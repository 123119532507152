.background{
    position: relative;
    background-image: url('../../assets/loginBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}