.custom-select {
    position: relative;
    user-select: none;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
}

.custom-select select {
    display: none;
}

.select-selected {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    width: 100%;
}

.select-items {
    background-color:  var(--chakra-colors-blue-100);
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 99;
}

.select-items div {
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.select-hide,
.chakra-select__icon {
    display: none;
}

.isLast {
    background-color: var(--chakra-colors-blue-500);
    color: var(--chakra-colors-gray-50);
}