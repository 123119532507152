@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600;700&display=swap');

body, html, #root{
    width: 100%;
    height: 100%;
    margin: 0 !important;
    font-family: 'Space Grotesk', sans-serif;
}

body, #root {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    overflow: auto;
}

::-webkit-scrollbar {
    display: none;
}

input { 
    background-color: var(--chakra-colors-blue-100) !important;
    border: none !important;
    font-size: 0.8rem !important;
}