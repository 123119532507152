.react-datepicker__header {
    background: none;
}

.react-datepicker__day-names {
    background-color: var(--chakra-colors-gray-700)
}

.react-datepicker__day-names>* {
    color: var(--chakra-colors-gray-50)
}

.react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker__day.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day.react-datepicker__day--selected {
    color: var(--chakra-colors-gray-50);
    background: var(--chakra-colors-blue-500);
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__navigation-icon::before {
    border-color: var(--chakra-colors-blue-500) !important;
    top: 50% !important;
}

.react-datepicker__navigation {
    top: 0 !important;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

div.react-datepicker-wrapper,
div>div.react-datepicker__input-container,
div>div.react-datepicker__input-container input {
    height: 100%;
    display: unset;
}